@font-face {
    font-family: Manrope;
    src: url('../constants/Manrope-VariableFont_wght.ttf');
    font-weight: normal;
    font-style: normal;
}

.Container {
    font-family: 'Manrope', sans-serif;

    h1 {
        font-weight: 600;
        font-size: 4rem;
        margin: 0;
    }

    h2 {
        font-weight: 600;
        font-size: 3rem;
        margin: 0;
    }

    h3 {
        font-weight: 600;
        font-size: 2rem;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: 1.5rem;
        margin: 0;
    }
}

.Title {
    width: 100%;
    height: fit-content;
    background-image: url('../assets/images/AboutPage/TtitleBg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h1 {
        width: auto;
        height: auto;
        text-align: center;

        padding: 189px 117px 199px 117px;
    }
}

.Hello {
    width: 100%;
    height: 385px;

    p {
        width: 1046px;
        height: 145px;

        padding: 80px 40px 160px 40px;
    }
}

.GroupPic {
    width: 100%;
    height: auto;
}

.Value {
    width: 100%;
    height: 1138px;

    h2 {
        width: auto;
        height: 58px;
        padding: 120px 24px 40px 24px;
    }

    .Values {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        width: auto;
        height: 800px;
        padding: 0 40px;
    }
}

.Team {
    .Text {
        width: 607px;
        height: auto;
        padding: 80px 0 40px 40px;

        h2 {
            margin-bottom: 24px;
        }
    }

    .Members {
        display: flex;
        flex-direction: row;
        width: auto;
        height: 432px;
        padding: 40px 40px 0 40px;
        gap: 24px;
    }
}

.Goals {
    .Container {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 768px) {
    .Title {
        display: none;
    }

    .Hello {
        height: auto;

        h2 {
            font-weight: 400;
            font-size: 1.5rem;
        }
    }

    .GroupPic {
        height: 428px;
    }

    .Value {
        height: auto;

        h2 {
            height: auto;
        }

        .Values {
            height: auto;
            padding-top: 80px;
        }
    }

    .Team {
        padding-top: 80px;

        .Text {
            width: 80%;
            height: auto;
        }

        .Members {
            display: none;
        }
    }
}

@media (max-width: 480px) {
    .Hello {
        p {
            width: auto;
            height: auto;
            padding-top: 80px;
            padding-bottom: 40px;
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .GroupPic {
        height: 266px;
    }

    .Value {
        h2 {
            padding-top: 60px;
            font-size: 2rem;
        }

        .Values {
            display: flex;
            flex-direction: column;
            height: auto;
            padding-top: 0;
            
        }
    }

    .Team {
        .Text {
            width: auto;
            padding: 0 24px 47px 24px;

            h2 {
                font-size: 2rem;
            }

            p {
                font-size: 18px;
            }
        }

        .Members {
            gap: 32px;
        }
    }

    .Goals {
        padding-top: 80px;
        padding-bottom: 201px;

        .Container {
            gap: 80px;
        }
    }
}
/* Global keyframes for animations */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes flip {
    0% {
        transform: rotateY(0deg);
    }

    100% {
        transform: rotateY(360deg);
    }
}

/* CSS for each component */
.loadingScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    /* Overlay background color with transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    overflow: hidden;
}

.container {
    position: relative;
    width: 100%;
    /* Adjust size as needed */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.borderOuter {
    width: 150px;
    /* Adjust size as needed */
    height: 150px;
    border: 5px solid rgba(255, 255, 255, 0.5);
    /* Outer border style */
    border-radius: 20px;
    /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotate 5s linear infinite;
}

.borderInner {
    width: 120px;
    /* Slightly smaller for inner border */
    height: 120px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    /* Inner border style */
    border-radius: 15px;
    /* Rounded corners */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: rotate 3s linear infinite;
}

.logoContainer {
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    position: absolute;
    z-index: 1;
    /* Ensure it stays on top of borders */
}
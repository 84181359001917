* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.servicesSection {
  padding: 60px 0;
  text-align: center;
  background-color: var(--foundationColor);
}

.sectionTitle {
  font-size: 32px;
  color: var(--titleColor);
  margin-bottom: 40px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  transition: transform 0.5s ease;

  .card {
    background-color: rgba(8, 8, 8, 0.8);
    text-align: left;
    width: 100%;
    border: 2px solid #1F1F1F;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    transition: background-color 0.3s ease, color 0.3s ease;
  
    &:hover {
      background: linear-gradient(to bottom, transparent, #FF543D80);
    }
  
    .iconImage {
      width: 100%;
      height: auto;
    }
  
    .title {
      font-size: 40px;
      color: #FFFFFF;
      font-weight: 400;
      padding-top: 60px;
      min-height: 180px;
      max-height: 360px;
    }
  
    .description {
      font-size: 24px;
      font-weight: 400;
      color: #FF9385;
      height: 108px;
    }
  
    .features-list {
      list-style: none;
      padding-left: 0;
      padding-top: 44px;
      min-height: 120px;
      height: 205px;
  
      li {
        display: flex;
        align-items: flex-start;
        font-size: 18px;
        font-weight: 400;
        color: #F1ECFF;
        padding-bottom: 12px;
  
        &::before {
          content: url('../../assets/images/homePageImages/homePageIconShape1.svg');
          position: relative;
          padding-right: 7px;
        }
      }
    }
  
    .button {
      background-color: var(--mainColor);
      border: none;
      color: white;
      padding: 8px 24px;
      border-radius: 56px;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      width: 196px;
      height: 48px;
      margin-top: 60px;
      transition: background-color 0.3s ease, color 0.3s ease;
  
      &:hover {
        background-color: white;
        color: black; 
      }
  
      .iconImg {
        padding-left: 8px;
        width: 24px;
        height: 24px;
      }
    }
  }
}

  
.indicators {
  display: none;
  justify-content: center;
  margin-top: 16px;
  
  span {
    height: 16px;
    width: 48px;
    background-color: #FF543D1A;
    margin: 4px;
    border: 1px solid #FF543D;
    border-radius: 56px;
    display: inline-block;
    cursor: pointer;
    transition: background-color 0.3s ease, border 0.3s ease;

    &.activeDot {
      background-color: #FF543D;
    }
  }
}

.linkButton {
  display: block;
  width: 180px;
}




@media (max-width: 768px) and (min-width: 481px) {
  .cardContainer {
    overflow-x: scroll;  

    &::-webkit-scrollbar {
      display: none;
    }

    .card{
      min-width: 413px;

    }
  }
}

@media (max-width: 768px) {
  .cardContainer {
    justify-content: start;
    padding-left: 24px;
    padding-right: 24px;

    .card {
      padding: 60px 42px;
      .title {
        font-size: 40px;
        padding-top: 32px;
        min-height: 0%;
      }

      .description {
        font-size: 20px;
        padding-top: 24px;
        height: 66px;
      }

      .features-list {
        padding-top: 44px;
        min-height: 96px;

        li {
          font-size: 16px;
        }
      }

      .button {
        font-size: 16px;
        width: 170px;
        height: 40px;
        margin-top: 40px;
        justify-content: center;
        padding-left: 8px;
        padding-right: 8px;

        .iconImg {
          padding-left: 8px;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .sectionTitle {
    font-size: 28px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .cardContainer {
    justify-content: flex-start;
    padding: 0;

  }

  .card {
    display: flex !important;
    margin: 0;
    padding: 0% !important;
    flex-direction: column;
    min-width: 99vw;
    max-width: 100vw !important;
    max-height: 1010px;

    .iconImage{
      padding-top: 60px;
      padding-left: 42px;
    }

    .title {
      font-size: 8.33vw;
      padding-top: 6.25vw;
      height: auto !important;
      padding-left: 42px;
      padding-right: 42px;

    }

    .description {
      font-size: 6.67vw;
      height: auto !important;
      padding-left: 42px;
      padding-right: 42px;

    }

    .features-list {
      padding-top: 4.17vw;
      height: auto !important;
      padding-left: 42px;
      padding-right: 42px;


      li {
        font-size: 3.33vw;
        padding-left: 42px;
        padding-right: 42px;

      }
    }

    .linkButton{
      padding-left: 42px;
      padding-bottom: 60px;
    }
    .button {
      width: 46vw !important;
      height: 7.5vw;
      margin-top: 6.25vw;
      font-size: 4vw !important;
      gap: 8px;
      padding-left: 42px;


      .iconImg {
        padding-left: 1px !important;
        width: 5vw !important;
        height: 5vw;
      }
    }
  }

  .indicators {
    display: flex;

    span {
      width: 2.5vw;
      height: 2.5vw;
    }
  }
}

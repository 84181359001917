.Container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 339px;
    gap: 10%;

    h2 {
        width: 20%;
        padding: 160px 0 121px 5%;
    }

    p {
        width: 60%;
        padding: 160px 5% 80px 0;
    }
}

@media (max-width: 480px) {
    .Container {
        flex-direction: column;
        gap: 24px;
        height: auto;

        h2 {
            width: 90%;
            padding: 0 5% 0 5%;
            font-weight: 400;
            font-size: 32px;
        }

        p {
            width: 90%;
            padding: 0 5% 0 5%;
            font-weight: 400;
            font-size: 20px;
        }
    }
}
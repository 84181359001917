* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden; // Ensures the slides stay within the viewport
}

.slideContainer {
  display: flex;
  transition: transform 0.5s ease; // Smooth transition for translateX
  transform: translateX(0); // Set by JavaScript logic
  
  .slide {
    width: 99.75%;
    background: #080808CC;
    border: 1px solid #1F1F1F;
    border-radius: 16px;
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    background: linear-gradient(to bottom right, transparent, transparent, #FF543D20, #FF543D40);
  }

  .textSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding-top: 80px;
    padding-left: 44px;
  
    h3 {
      font-size: 48px;
      font-weight: 400;
      margin-bottom: 16px;
      color: var(--titleColor);
    }
  
    p {
      padding-top: 44px;
      font-size: 24px;
      color: #BFBFBF;
      padding-bottom: 200px;
    }
  }
  
  .iconSection {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
  
    img {
      width: 90%;
      max-height: 90%;
    }
  }
}

.indicators {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  span {
    height: 16px;
    width: 48px;
    background-color: #FF543D1A;
    margin: 4px;
    border: 1px solid #FF543D;
    border-radius: 56px;
    display: inline-block;
    cursor: pointer;
  }

  .activeDot {
    background-color: #FF543D;
  }
}

@media (max-width: 768px) and (min-width: 481px) {
  .slideContainer {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin-left: 24px;
    max-height: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .slide{
      width: 413px;
    }
  }
}

@media (max-width: 768px) {
  .slideContainer {
    margin-left: 24px;
    max-height: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .slide {
      display: flex !important;
      margin-left: 16px;
      margin-right: 16px;
      flex-direction: column;
      align-items: center;

      .textSection {
        padding: 0;
        width: 100%;

        h3 {
          font-size: 32px;
          padding-top: 32px;
          padding-left: 24px;
          padding-right: 24px;
        }

        p {
          font-size: 18px;
          padding-top: 24px;
          padding-bottom: 96px;
          padding-left: 24px;
          padding-right: 24px;
        }
      }

      .iconSection {
        padding: 10px;
        padding-left: 0%;
        padding-right: 0%;
        padding-bottom: 32px;

        img {
          width: calc(100% - 16px);
          height: 341px;
          max-height: 300px;
          padding-bottom: 32px;
        }
      }
    }
  }

  .indicators {
    display: none;
  }
}

@media (max-width: 480px) {
  .slideContainer {
    justify-content: flex-start; 
    padding: 0 !important;
    margin: 0 !important;

    .slide {
      min-width: 100%;
      margin: 0%;
      display: flex !important;
      margin: 0%;

      .textSection {
        h3 {
          font-size: 32px;
        }

        p {
          font-size: 16px;
        }
      }

      .iconSection {
        padding-left: 24px;
        padding-right: 24px;

        img {
          width: 100%;
          max-height: auto;
          padding-bottom: 32px;
          
        }
      }
    }
  }

  .indicators {
    display: flex;

    span {
      width: 12px;
      height: 12px;
    }
  }
}

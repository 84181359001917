* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope' , sans-serif;
}

.errorMessages{
  display: flex;
  flex-direction: column;
  gap: 0;
  margin: 0;
  padding: 10px;
  border: none;
  text-align: center;
  color: red;
  font-size: large;
}

.contactPage {
  background-color: #000;
  .contactFormSection {
    text-align: center;
    color: white;
    background-image: url('../assets/images/contactPageImages/contactPageLogo.png'), url('../assets/images/contactPageImages/contactPageGradient1.png');
    background-size: cover;
    background-repeat: no-repeat;

    h2 {
      font-weight: 600;
      font-size: 64px;
      padding-bottom: 16px;
      padding-top: 125px;
      .highlight {
        color: #FF543D;
      }
    }

    p {
      font-size: 24px;
      padding-bottom: 115px;
      padding-left: 247px;
      padding-right: 247px;
      color: #BFBFBF;
    }

    .contactForm {
      display: flex;
      flex-direction: column;
      gap: 40px;
      color: #8C8C8C;
      padding-left: 90px;
      padding-right: 90px;
    
      .inputGrid {
        width: auto;
        display: grid;
        gap: 16px;
    
        input,
        textarea {
          padding:  16px 24px;
          border: 1px solid #1F1F1F;
          border-radius: 4px;
          font-size: 18px;
          font-weight: 600;
          color: #8C8C8C;
          background: #14141499;
          overflow: hidden;
          box-sizing: border-box;
          
          &:focus {
            outline: none;
            border: 1px solid #FF543D;
          }

          &:not(:placeholder-shown) {
            background-color: #FF543D2A;
            border: 1px solid #FF543D;
            color: white;
          }
        
        }
        
    
        textarea {
          grid-column: span 2;
          resize: vertical;
        }
      }

      .submitButton {
        grid-column: span 2;
        padding: 12px;
        background-color: #FF543D;
        border: none;
        color: white;
        font-weight: bold;
        cursor: pointer;
        border-radius: 56px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: #FF543D52;
          border: 1px solid #FF543D;

          .iconArrow {
            display: none;
          }
        }

        .iconArrow {
          margin-left: 8px; 
        }
      }
    }
  }

  .mapSection {
    padding-top: 200px;
    padding-bottom: 151px;
    padding-left: 92px;
    padding-right: 92px;

    .mapContainer {
      height: 623px;
      width: 100%;

      a {
        text-decoration: none;
        color: #FF543D;
    
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.leaflet-control-attribution {
  display: none !important;
}

@media (max-width: 768px) {
    .contactPage {
      background: linear-gradient(200deg,#000,#73261B, #461d17, #000, #000, #000, #000, #000);
      .contactFormSection {
        background-image: url('../assets/images/contactPageImages/contactPageLogo.png'), url('../assets/images/contactPageImages/contactPageGradient2.png');
        background-size: cover;
        background-repeat: no-repeat;
        h2 {
          font-size: 56px;
          padding-left: 71px;
          padding-right: 71px;
        }
  
        p {
          font-size: 18px;
          padding-left: 71px;
          padding-right: 62px;
        }
  
        .contactForm {
          padding-left: 24px;
          padding-right: 24px;

          .inputGrid {
            grid-template-columns: none;
            input {
              width: 100%;
            }
            textarea {
              width: 100%;
            }
          }

          .submitButton {
            .iconArrow {
              display: none;
            }
          }
        }
      }
  
      .mapSection {
        padding-top: 120px;
        padding-bottom: 120px;
        padding-left: 24px;
        padding-right: 24px;

        .mapContainer {
          height: 407px;
          width: 100%;
        }
      }
    }
  }

@media (max-width: 480px) {
    .contactPage {
      .contactFormSection {
        background-image: url('../assets/images/contactPageImages/contactPageBackground.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        h2 {
          font-size: 40px;
          padding-top: 80px;
          padding-bottom: 20px;
          padding-left: 24px;
          padding-right: 24px;
        }
  
        p {
          font-size: 18px;
          padding-bottom: 24px;
          padding-left: 24px;
          padding-right: 24px;
        }
  
        .contactForm {
          padding-left: 24px;
          padding-right: 24px;
          gap: 29px;

          .inputGrid {
            display: table-column;
            input {
              width: 100%;
              margin-bottom: 16px;
            }
            textarea {
              width: 100%;
              background-color: rgba(014, 014, 014, 0.6);
            }
          }

          .submitButton {
            margin-bottom: 5px;
          }
        }
      }
  
      .mapSection {
        padding-top: 115px;
        padding-bottom: 160px;
        padding-left: 5vw;
        padding-right: 5vw;

        .mapContainer {

          height: 80vh;
          width: 100%;
        }
      }
    }
  
}
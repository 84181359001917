@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #000;
    color: #fff;
    position: relative;
    background-image: url('../../assets/Footer/Start\ now.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .begin {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        height: 802px;
        align-items: center;
        justify-content: center;
        text-align: center;

        .beginContainer {
            box-sizing: border-box;
            width: 979px;
            height: 282px;
            display: flex;
            flex-direction: column;
            gap: 80px;

            .head {
                box-sizing: border-box;
                text-align: center;
                width: 100%;

                h1 {
                    font-weight: 600;
                    font-size: 64px;
                    height: 154px;
                    line-height: 76.8px;
                }
            }

            .subtext {
                display: flex;
                box-sizing: border-box;
                width: 100%;
                height: 128px;
                align-items: center;
                justify-content: center;

                .ButtonContainer {
                    box-sizing: border-box;
                    width: 423px;
                    height: 48px;
                    display: flex;
                    flex-direction: row;
                    gap: 23px;

                    .button2 {
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .foot {
        box-sizing: border-box;
        position: relative;
        height: 472px;
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;
        background-image: url('../../assets/Footer/Footer.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .col1 {
            box-sizing: border-box;
            width: 50%;
            display: flex;
            flex-direction: column;
            gap: 39px;
            padding: 206px 50px 40px 40px;

            .Col1 {
                width: 141px;
                height: 79px;
            }

            .Col2 {
                width: 100%;
                height: 108px;
                display: flex;
                flex-direction: column;
                gap: 16px;
                color: #BFBFBF;

                .Address {
                    width: 100%;
                    height: 38px;

                    .address {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                    }
                }

                .Phone {
                    width: 100%;
                    height: 38px;

                    .phone {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                    }
                }

                .Mail {
                    width: 100%;
                    height: 38px;

                    .mail {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 19.2px;
                    }
                }
            }
        }

        .col2 {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            width: 50%;
            padding: 248px 40px 46px 40px;
            gap: 26px;
            justify-content: center;

            .Col2_1 {
                width: 178px;
                height: 178px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                gap: 32px;
                color: #FFFFFF;

                p {
                    cursor: pointer;
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .home {
                    width: 76px;
                    height: 19px;

                    .Home {
                        width: 100%;
                        height: 19px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.2px;
                    }
                }

                .service {
                    width: 76px;
                    height: 19px;

                    .Service {
                        width: 100%;
                        height: 19px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.2px;
                    }
                }

                .about {
                    width: 106px;
                    height: 22px;

                    .About {
                        width: 100%;
                        height: 19px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.2px;
                    }
                }

                .contact {
                    width: 76px;
                    height: 22px;

                    .Contact {
                        width: 100%;
                        height: 19px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 19.2px;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .container {
        box-sizing: border-box;
        width: 100%;

        .begin {
            box-sizing: border-box;
            width: 100%;
            height: 462px;

            .beginContainer {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                width: 720px;
                gap: 58px;

                .head {
                    box-sizing: border-box;
                    height: 116px;
                    width: 100%;
                    align-items: normal;

                    h1 {
                        font-size: 6.25vw;
                        text-align: center;
                        line-height: 57.6px;
                        font-weight: 600;
                    }
                }

                .subtext {
                    box-sizing: border-box;
                    height: 106px;

                    .button {
                        width: 423px;
                        height: 48px;
                    }
                }
            }
        }

        .foot {
            width: 100%;
            height: 469px;

            .col1 {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                width: 50%;
                padding: 180px 0 29px 24px;
                gap: 38px;

                .Col1 {
                    width: 141px;
                    height: 79px;
                }

                .Col2 {
                    box-sizing: border-box;
                    width: 100%;
                    height: 146px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    color: #FFFFFF;

                    .Address {
                        box-sizing: border-box;
                        width: 100%;
                        height: 76px;
                        
                        .address {
                            font-size: 2.083vw;
                        }
                    }

                    .Phone {
                        box-sizing: border-box;
                        width: 100%;
                        height: 76px;
                        
                        .phone {
                            font-size: 2.083vw;
                        }}

                    .Mail {
                        box-sizing: border-box;
                        width: 100%;
                        height: 76px;
                        
                        .mail {
                            font-size: 2.083vw;
                        }
                    }
                }
            }

            .col2 {
                box-sizing: border-box;
                width: 50%;
                display: flex;
                flex-direction: row;
                padding: 0;
                padding-top: 221px;
                padding-bottom: 67px;
                padding-right: 24px;
                padding-left: 24px;

                .Col2_1 {
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    gap: 32px;

                    .home {
                        width: 85px;

                        .Home {
                            width: 100%;
                            height: 22px;
                            font-size: 2.344vw;
                            line-height: 21.6px;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .service {
                        width: 85px;

                        .Service {
                            width: 100%;
                            height: 22px;
                            font-size: 2.344vw;
                            line-height: 21.6px;
                        }
                    }

                    .about {
                        width: 100%;

                        .About {
                            width: 100%;
                            height: 22px;
                            font-size: 2.344vw;
                            line-height: 21.6px;
                        }
                    }

                    .contact {
                        width: 85px;

                        .Contact {
                            width: 100%;
                            height: 22px;
                            font-size: 2.344vw;
                            line-height: 21.6px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .container {
        width: 100%;
        height: 1231px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        background-image: url('../../assets/Footer/Footer.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        .begin {
            width: 100%;
            height: 575px;
            position: relative;
            background-image: url('../../assets/Footer/halo.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            
            .beginContainer {
                display: flex;
                box-sizing: border-box;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .head {
                    box-sizing: border-box;
                    width: 88.542vw;
                    
                    h1 {
                        width: 100%;
                        height: 144px;
                        font-size: 8.333vw;
                    }
                }

                .subtext {
                    box-sizing: border-box;
                    width: 423px;
                    
                    .ButtonContainer {
                        height: 48px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        .foot {
            box-sizing: border-box;
            width: 100%;
            height: 656px;
            flex-direction: column;
            background-image: none;
            position: relative;

            .col1 {
                box-sizing: border-box;
                width: 100%;
                padding: 0;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 64px;
                padding-bottom: 0;
                flex-direction: column;

                .Col1 {
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .Col2 {
                    box-sizing: border-box;
                    flex-direction: column;
                    width: 100%;
                    height: 108px;
                    gap: 16px;

                    .Address {
                        height: 38px;

                        .address {
                            width: 100%;
                            font-size: 3.333vw;
                        }
                    }

                    .Phone {
                        height: 38px;

                        .phone {
                            font-size: 3.333vw;
                        }
                    }

                    .Mail {
                        height: 38px;

                        .mail {
                            font-size: 3.333vw;
                        }
                    }
                }
            }

            .col2 {
                box-sizing: border-box;
                width: 100%;
                padding: 0;
                padding: 0 24px;
                padding-top: 68px;
                flex-direction: row;
                gap: 40px;

                .Col2_1 {
                    width: 100%;
                    height: 184px;
                    flex-direction: column;
                    gap: 32px;

                    .home {
                        width: 100%;
                        
                        .Home {
                            height: 22px;
                            font-size: 3.75vw;
                            line-height: 21.6px;
                        }
                    }

                    .service {
                        width: 100%;
                        
                        .Service {
                            height: 22px;
                            font-size: 3.75vw;
                            line-height: 21.6px;
                        }
                    }

                    .about {
                        width: 100%;
                        
                        .About {
                            height: 22px;
                            font-size: 3.75vw;
                            line-height: 21.6px;
                        }
                    }

                    .contact {
                        width: 100%;
                        
                        .Contact {
                            height: 22px;
                            font-size: 3.75vw;
                            line-height: 21.6px;
                        } 
                    }
                }
            }
        }
    }
}
.container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow-x: hidden;

    overflow-y: scroll;
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */

    /* Hide scrollbar in Webkit browsers */
    &::-webkit-scrollbar {
        display: none;
    }

        .body {
            flex: 1;
        }

        // Responsive design for tablets and smaller screens
        @media (max-width: 768px) {}

        // Responsive design for mobile devices
        @media (max-width: 480px) {}

    }
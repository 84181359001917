:root {
  --foundationColor: #000000;
  --mainColor: #FF543D;
  --textColor1: #BFBFBF;
  --titleColor: #FFFFFF;
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Manrope' , sans-serif;
}

.body { 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--foundationColor);
  color: var(--titleColor);

  /*Hero Section*/

  .heroContent {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-height: 1035px;

    .heroText {
      width: 100%;
      height: auto;
      text-align: center;
      margin-top: 120px;  
      padding-left: 24px;
      padding-right: 24px;

      .heroTitle {
        font-size: 64px;
        font-weight: 600;
        padding-left: 24px;
        padding-right: 24px;
      }
  
      .description {
        font-size: 24px;
        font-weight: 400;
        padding-top: 32px;
        color: var(--textColor1);
        padding-left: 24px;
        padding-right: 24px;
      }
    }

  .buttonContainer {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    gap: 16px;
    width: 100%;
    max-width: 500px;
  }

  .buttonContainer a {
    text-decoration: none;
  }
}

  .partners {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: 60px;

    .backgroundContainer {
      position: relative;
      width: 100%;
      height: auto;

      .backgroundImage {
        width: 100%;
        height: auto;
        display: flex;
      }

      .overlayText {
        position: absolute;
        top: 73%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: var(--textColor1);
        font-size: 20px;
        text-align: center;
        font-weight: 400;
      }
  
      .brandLogos {
        position: absolute;
        top: 97%;
        left: 49%;
        transform: translateX(-50%);
        background: linear-gradient(to bottom, transparent, #000000);
        display: flex;
        align-items: space-around;
        gap: 24px;
        width: 100% !important;
        padding-left: 24px;
        cursor: pointer;
        overflow-x: scroll;
        scrollbar-width: none;
        -webkit-overflow-scrolling: touch;
        scroll-snap-align: start;

        &::-webkit-scrollbar {
          display: none;
        }
        
  
        .brandsImage {
          height: 3.5vh;
          width: 100%;
        }

        .brandImage {
          height: 3.5vh;
          width: auto;
        }
      }
    }
  }

  /*Service Section*/

  .servicesSection {
    padding-top: 200px;
    background-color: #000000;
    text-align: center;
    width: 100%;

    .sectionTitle {
      font-size: 40px;
      font-weight: 600;
      color: #FFFFFF;
      text-align: center;
    }
  }

  /*Carousel*/

  .carouselSection {
    position: relative;
    height: auto;
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;

    .carouselTitle {
      font-size: 40px;
      font-weight: 600;
      text-align: center;
      padding-top: 80px;
      padding-bottom: 60px;
      background-image: url('../assets/images/homePageImages/homePageImage3.png');
      background-size: 952px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}

@media (max-width: 768px) {
  .body {
    .heroContent {
      background: linear-gradient(to bottom, #461d17, #000, #000, #000, #000, #000);
      
      .heroText {
        padding-top: 96px;

        .heroTitle {
          font-size: 56px;
        }

        .description {
          font-size: 18px;
        }
      }

      .buttonContainer {
        padding-top: 40px;
        gap: 8px;
      }
    }

    .partners {
      .backgroundContainer {
        .backgroundImage {
          width: 100%;
          height: auto;
        }
        
        .overlayText {
          font-size: 18px;
          width: 100%;
        }

        .brandLogos {
          top: 95%;

          .brandsImage {
            height: 18px;
            width: 100%;
          }
        }
      }
    }

    .servicesSection {
      padding-top: 140px;

      .sectionTitle {
        font-size: 32px;
      }
    }

    .carouselSection {
      height: auto;
      padding-top: 116px;

      .carouselTitle {
        font-size: 32px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 48px;

        .numberOne {
          display: block;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .body {
    .heroContent {
      background: linear-gradient(to bottom, #461d17, #000, #000, #000); 

      .heroText {
        .heroTitle {
          font-size: 40px;
        }

        .description {
          font-size: 16px;
          padding-top: 20px;
        }
      }

      .buttonContainer {
        min-height: 48px;
        padding-top: 40px;
        gap: 16px;
      }
    }

    .partners {
      .backgroundContainer {
        .backgroundImage {
          width: 100%;
          height: 340px;
        }
        
        .overlayText {
          font-size: 16px;
          width: 100%;
        }

        .brandLogos {
          top: 95.5%;

        }
      }
    }

    .carouselSection {
      height: auto;

      .carouselTitle {
        font-size: 28px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: 178px;
        padding-bottom: 94px;
        background-image: none;
      }
    }
  }
}
